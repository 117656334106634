/**
 * @file standalone/content_block.scss
 *
 * \brief Library file - shared styles for content blocks
 *
 */

@import '../theme-bootstrap';

.content-block {
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 0;
  &[data-clickable],
  [data-clickable] {
    cursor: pointer;
  }
  b,
  strong {
    font-weight: bold;
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  &__content-wrapper {
    width: 100%;
    height: 100%;
    &--positioned {
      position: absolute;
      z-index: 3;
      top: 0;
      #{$ldirection}: 0;
    }
    &--positioned-stretched {
      position: absolute;
      z-index: auto;
      top: 0;
      bottom: 0;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
    }
    > .align-#{$ldirection},
    > .align-#{$rdirection},
    > .align-center {
      display: block;
    }
  }
  &__line {
    // make sure we do not pick up flex alignment rules
    display: block;
    padding-bottom: 0;
    @media #{$medium-up} {
      padding-bottom: 12px;
    }
    // compensate for the WYSIWYG adding extra paragraphs
    p {
      margin: 0;
      padding: 0;
    }
    &--header {
      padding-top: 12px;
    }
  }
  &__link {
    padding-bottom: 1em;
  }
  &__links {
    display: block;
    min-height: 1.5em;
    padding-bottom: 12px;
    &--2up {
      > div:first-of-type {
        margin-bottom: 20px;
      }
    }
  }
  // Adding for the cases where we are not using mantle media.
  // We need to make sure we fill containers.
  &__picture {
    &-media-asset {
      width: 100%;
      height: 100%;
      img,
      picture {
        height: auto;
        max-width: 100%;
        width: 100%;
      }
    }
  }
  // ELC basic tout
  &.basic-tout {
    overflow: unset;
    @media (min-width: $tablet-width-min) {
      overflow: hidden;
    }
    .content-block {
      overflow: unset;
    }
    h4 {
      font-size: 34px;
      line-height: 0.9;
      padding-bottom: 10px;
      @media (min-width: $tablet-width-min) {
        font-size: 30px;
        line-height: initial;
        padding-bottom: initial;
      }
    }
    &__content {
      height: auto;
    }
    &__content-wrapper {
      position: relative;
    }
    &__media-wrapper {
      height: auto;
    }
    .content-block__links {
      a {
        text-decoration: underline;
        font-weight: bold;
      }
    }
    .mobile-flex-hidden {
      display: none;
      @media (min-width: $tablet-width-min) {
        display: flex;
      }
    }
    .pc-hidden {
      @media (min-width: $tablet-width-min) {
        display: none;
      }
    }
    .mantle-media-asset img,
    .mantle-media-asset picture,
    .mantle-media-asset video {
      position: relative;
      #{$ldirection}: 50%;
      #{$rdirection}: 50%;
      max-width: 100vw;
      width: 100vw;
      margin-#{$ldirection}: -50vw;
      margin-#{$rdirection}: -50vw;
      @media (min-width: $tablet-width-min) {
        position: unset;
        max-width: 100%;
        width: 100%;
        #{$ldirection}: unset;
        #{$rdirection}: unset;
        margin-#{$ldirection}: unset;
        margin-#{$rdirection}: unset;
      }
    }
  }
  .basic-tout {
    &__wrapper {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
    }
    &__content-below-block {
      display: block;
    }
    &__content-wrapper {
      position: relative;
    }
    &__content-over-media {
      position: initial;
      background-color: $color-white;
      top: 0;
      bottom: 0;
      #{$ldirection}: 0;
      #{$rdirection}: 0;
      @media (min-width: $tablet-width-min) {
        position: absolute;
        background-color: transparent;
      }
    }
  }
}
